<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top mb-10">
                <h2><span>■</span> 고객정보</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-200px">고객</th>
                                <td class="left pl-20">
                                    {{clientName}}
                                </td>
                                <th>상담시간</th>
                                <td class="left pl-20">
                                    {{consDate}}<br>[시간] {{consTime}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담구분</th>
                                <td class="left pl-20">
                                    {{productName}}
                                </td>
                                <th>상담유형</th>
                                <td class="left pl-20">
                                    {{typeName}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담주제</th>
                                <td class="left pl-20" colspan="3">
                                    {{subjectName}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">사용가능회기</th>
                                <td class="left pl-20" colspan="3">
                                    {{consCountRest}} 회 (총{{consCount}}회)
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-200px">전체상담의목표</th>
                                <td class="left pl-20" colspan="3">
                                    {{goal}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담계획</th>
                                <td class="left pl-20" colspan="3">
                                    {{plan}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담목록</h2>
                    </div>
                    <table class="default_table mt-20">
                        <tbody>
                            <tr>
                                <th>날짜</th>
                                <th>고객</th>
                                <th>상담유형</th>
                                <th>진행현황</th>
                                <th>승인현황</th>
                                <th>일지작성</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
                                <td>{{item.consDate.slice(0,10)}}</td>
                                <td>{{item.clientName}}</td>
                                <td>{{item.eapType}}</td>
                                <td>{{item.processStatus}}</td>
                                <td>{{item.permission}}</td>
                                <td><span class="underline pointer" @click="preview(reportList[index])">상세내용</span></td>
                            </tr>
                            <tr v-if="reportList.length === 0">
                                <td colspan="8">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
    <preview :onModal.sync="onModal" :report.sync="report"></preview>
</div>
</template>

<script>
import Preview from '@/pages/convention/journalPreview.vue'
import {
    GetUrlParams
} from '@/utils/common.js'
export default {

    components: {
        Preview
    },

    data: () => ({
        attachFile: '', // 상담일지 첨부파일
        idx: -1, // resrvation idx
        // reportVO 값
        attachFileName: '',
        attachFileUrl: '',
        clientName: '',
        consOpinion: '',
        mentalTest: '',
        nextPlan: '',
        consContents: '',
        attachFileName: '',
        attachFileUrl: '',
        processStatus: '',
        dangerStepTitle: '',
        mainProblem: '',
        consProcess: '',

        // reservationVO 값
        typeName: '',
        consDate: '',
        consTime: '',
        reservationStatus: '',
        memo: '',
        centerName: '',
        goal: '',
        plan: '',
        consCount: 0,
        consCountRest: 0,

        //clientVO 값
        idxCrmClient: -1,
        typeName: '',
        gubun: '',
        productName: '',
        subjectName: '',
        consCountRest: 0,

        onModal: false, // 미리보기 모달 on/off
        reportList: [],
        report: {}

    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getReport()
    },

    methods: {

        // 상담일지 미리보기 (모달 on)
        preview(reportList) {
            this.onModal = !this.onModal
            this.report = reportList
        },

        // 상담일지 닫기 (모달 off)
        offModal() {
            this.onModal = false
        },

        // 상담일지 내용
        getReport() {
            this.axios.get(`/api/v1/cons/report/${this.idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.clientName = res.data.clientVO.name
                        this.consDate = res.data.reservationVO.consDate.slice(0, 4) + "년 " + res.data.reservationVO.consDate.slice(5, 7) + "월 " + res.data.reservationVO.consDate.slice(8, 10) + "일"
                        this.consTime = res.data.reservationVO.consTime

                        this.productName = res.data.clientVO.productName
                        this.typeName = res.data.clientVO.typeName
                        this.subjectName = res.data.clientVO.subjectName
                        this.consCount = res.data.clientVO.consCount
                        this.consCountRest = res.data.clientVO.consCountRest
                        this.dangerStepTitle = res.data.reportVO.dangerStepTitle
                        this.goal = res.data.clientVO.goal
                        this.mainProblem = res.data.reportVO.mainProblem
                        this.plan = res.data.clientVO.plan
                        this.consProcess = res.data.reportVO.consProcess
                        this.consOpinion = res.data.reportVO.consOpinion
                        this.mentalTest = res.data.reportVO.mentalTest
                        this.nextPlan = res.data.reportVO.nextPlan
                        this.consContents = res.data.reportVO.consContents

                        this.attachFileName = res.data.reportVO.attachFileName
                        this.attachFileUrl = res.data.reportVO.attachFileUrl

                        this.idxCrmClient = res.data.clientVO.idx
                        this.getReportList()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상담일지 목록
        getReportList() {
            var params = {
                idxCrmClient: this.idxCrmClient
            }

            this.axios.get('/api/v1/cons/report/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList

                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reportList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상담일지 파일 업로드
        uploadFile(event) {
            // // 포맷 검사
            // var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif']
            // var checked = false
            // for (var item of allowFormat) {
            //     if (event.target.files[0].name.includes(item)) {
            //         checked = true
            //         break
            //     } else {
            //         continue
            //     }
            // }

            // 용량 검사
            if (event.target.files[0].size > 1048576 * 2) {
                alert('1MB 이하만 업로드 가능합니다.')
                this.$refs.attachFile.value = ''
                return false
            } else {
                this.attachFile = event.target.files[0]

                this.$refs.attachFile.value = ''
            }
        },
    }
}
</script>
