<template>
<!-- 상태 변경 팝업 -->
<transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onModal" @click.self="handleWrapperClick">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="width:1000px">
                <div class="modal-body">
                    <div>
                        <p class="bold dp-inblock h-30px font-23">상담일지 미리보기</p>
                        <!-- <a class="pointer float-right layer-close ml-30" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
                            <img src="@/assets/images/close.png" alt="close" class="close-btn">
                        </a> -->
                        <div class="con">
                            <div class="con_table mt-20">
                                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                                    <tbody>
                                        <tr>
                                            <th class="bold w-100px">주 호소 문제</th>
                                            <td class="left" colspan="3">
                                                {{report.mainProblem}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="bold w-100px">상담과정</th>
                                            <td class="left" colspan="3">
                                                {{report.consProcess}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="bold w-100px">상담내용</th>
                                            <td class="left" colspan="3">
                                                {{report.consContents}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="bold w-100px">상담사소견</th>
                                            <td class="left" colspan="3">
                                                {{report.consOpinion}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>심리검사</th>
                                            <td class="left" colspan="3">
                                                {{report.mentalTest}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이후계획</th>
                                            <td class="left" colspan="3">
                                                {{report.nextPlan}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>첨부</th>
                                            <td class="left pl-20">
                                                <div v-if="report.attachFileUrl && report.attachFileUrl !== 'null'">
                                                    <a target="blank" :href="report.attachFileUrl" class="w-300px pointer txt-black">{{report.attachFileName}}</a>
                                                </div>
                                                <div v-else>파일 없음</div>
                                                
                                                <div v-if="report.attachFileUrl2 && report.attachFileUrl2 !== 'null'">
                                                    <a target="blank" :href="report.attachFileUrl2" class="w-300px pointer txt-black">{{report.attachFileName2}}</a>
                                                </div>
                                                <div v-else>파일 없음</div>

                                                <div v-if="report.attachFileUrl3 && report.attachFileUrl3 !== 'null'">
                                                    <a target="blank" :href="report.attachFileUrl3" class="w-300px pointer txt-black">{{report.attachFileName3}}</a>
                                                </div>
                                                <div v-else>파일 없음</div>

                                                <div v-if="report.attachFileUrl4 && report.attachFileUrl4 !== 'null'">
                                                    <a target="blank" :href="report.attachFileUrl4" class="w-300px pointer txt-black">{{report.attachFileName4}}</a>
                                                </div>
                                                <div v-else>파일 없음</div>

                                                <div v-if="report.attachFileUrl5 && report.attachFileUrl5 !== 'null'">
                                                    <a target="blank" :href="report.attachFileUrl5" class="w-300px pointer txt-black">{{report.attachFileName5}}</a>
                                                </div>
                                                <div v-else>파일 없음</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="btns3 mgB80">
                                    <!-- <a class="btn_sms mr-10 pointer" @click="$emit('close')">닫기</a> -->
                                    <a class="btn_sms mr-10 pointer" @click="$emit('update:onModal', !onModal)">닫기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {

    props: {
        onModal: {
            type: Boolean,
            require: true,
            default: false
        },
        report: {
            type: Object,
            require: true
        }
    },

    data: () => ({}),

    mounted() {},

    methods: {

        handleWrapperClick() {
            this.$emit('update:onModal', false)
        },
    }
}
</script>

<style lang="scss" scoped>
.modal {
    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        padding: 10px 20px;
        background: #fff;
    }

    &-footer {
        // background: #ccc;
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}
</style>
